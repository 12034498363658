@mixin statistic-card($background-color, $font-color) {
  background-color: $background-color;
  color: $font-color;

  border-radius: 3rem;
  padding: 2rem;
  box-sizing: border-box;
  border: 1px solid $primary-var-grey;
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 2rem;
  &--icon,
  &--content {
    display: flex;
    justify-content: center;
  }
  &--icon {
    align-items: center;
  }
  &--content {
    flex-direction: column;
    align-items: flex-start;
  }

  // Variations
  &.clickable {
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      border-color: var(--black);
      @include box-shadow();
    }
  }
  &.danger {
    background-color: var(--var-red);
    border-color: var(--red);
  }

  &.success {
    background-color: var(--var-green);
    border-color: var(--success);
  }
}

// INFO CARD
.info-card {
  border-radius: 3rem;
  padding: 2rem 3rem;
  box-sizing: border-box;
  border: 1px solid $primary-var-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  hyphens: auto;
  // word-break: break-all;

  &--light-primary {
    background-color: $primary-var-light-blue;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
  }

  &.add-quotation {
    .info-card--header {
      @include mobile {
        display: block;
        button {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }

  .info-card--body {
    .list-element {
      @extend p, .bullet-element;
      min-height: 3rem;

      // bullets
      &::before {
        flex-shrink: 0;
      }

      &.not-selected {
        &::before {
          border: 1px solid $primary-light-blue;
          background-color: transparent;
          padding: 0.5rem;
        }
      }

      &.selected {
        &::before {
          border: 1px solid $primary-light-blue;
          background-color: $primary-light-blue;
          padding: 0.5rem;
        }
      }
    }

    .list-subelement {
      align-items: baseline;
      display: flex;
      font-weight: 500;
      min-height: 3rem;
      padding: 0.5rem 0;

      // dash
      &::before {
        content: '';
        display: list-item;
        list-style-type: '- ';
        margin-left: 4rem;
      }

      .text-color-danger {
        font-weight: bold;
      }
    }

    .grid-text {
      display: grid;
      grid-template-columns: 1fr auto auto;

      &__link {
        color: $gray-900;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: $primary-light-blue;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }

  .info-card--grid {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    .card-grid--name {
      display: flex;
      align-items: center;
    }
    .list-element {
      @extend p, .bullet-element;
      align-items: center;

      // bullets
      &::before {
        background-color: $primary-dark-blue; /* Change the color */
      }
    }
  }

  &--footer {
    padding-top: 2rem;
    display: flex;
    flex-direction: row-reverse;
  }
}

// STATISTIC CARD
.statistic-card {
  @include statistic-card(transparent, $primary-dark-blue);
  padding: 2.2rem;
}

// HOME WELCOME CARD
.home-welcome-card {
  @include statistic-card(#bce7fb, $primary-dark-blue);
  border: none;
  padding: 3rem 2rem;
  column-gap: 0;

  @include mobile {
    grid-template-columns: 1fr;
    gap: 1rem;
    &--content > * {
      text-align: center;
      margin: 0 auto;
    }
  }

  button {
    opacity: 1 !important;
  }
  i {
    transform: scale(3);
    color: $primary-dark-blue;
  }
}

// HOME PROFILE CARD

.home-profile-card {
  display: grid;
  grid-template-columns: 3fr 7fr 1fr;
  border-radius: 3rem;
  overflow: hidden;
  background-color: $primary-var-light-grey;
  padding: 0.5rem 1rem;

  @include from-mid-screens {
    grid-template-columns: 2fr 2fr 1fr;
    gap: 1rem;
    padding: 1rem;
    &--content > * {
      text-align: center;
      margin: 0 auto;
    }
  }

  &--image {
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    width: 10rem;
    height: 10rem;

    &__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: $primary-var-grey;
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
  &--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .divider {
      margin: 0.75rem 0 !important;
      border-color: $primary-var-grey;
    }
  }
  &--action {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }
}

// SIMPLE NUMERIC CARD
.simple-numeric-card {
  @include statistic-card(transparent, $black);
  display: flex;
  justify-content: space-between;

  &--number-container {
    --background-color: var(--secondary-blue);
    --color: var(--white);
    flex-shrink: 0;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    color: var(--color) !important;
    h4 {
      color: inherit;
    }

    // Variants
    &.primary-var-blue {
      --background-color: var(--primary-var-blue);
      --color: var(--black);
    }
  }
}

.basic-card {
  color: $gray-900;
  cursor: pointer;
  border-radius: 3rem;
  border-width: 2px;
  border-bottom-width: thick;
  padding: 1.5rem;

  &:hover {
    box-shadow: $box-shadow;
  }

  &:active {
    border-color: $primary-dark-blue;
  }

  .card-body > .card-title {
    @extend p;
    @extend .section-title;
  }

  .header-text {
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    position: absolute;
    top: 55px;
    left: 0px;
    width: 100%;
    text-align: center;
  }
}

// IconNumberCard

.icon-number-card {
  @include statistic-card(transparent, $primary-dark-blue);
  display: block;
  height: 100%;
  padding: 2rem 2.5rem;

  &__title,
  &__value {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    gap: 1.5rem;
  }

  &__title {
    @extend p, .detail;
    text-transform: uppercase;
    font-size: clamp(0.8rem, 1vw, 1.1rem);
    font-weight: bold;
    margin-bottom: 1.25rem;
  }

  &__value {
    @extend p, .detail;
    font-size: $h4-font-size;
    font-weight: 300;
  }
}

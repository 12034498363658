.borderless {
  border: none;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.rounded-border {
  border-radius: 100rem !important;
}

.rounded-medium {
  border-radius: 1rem !important;
}

.capitalize-text {
  &::first-letter {
    text-transform: uppercase;
  }
}

.opaque {
  opacity: 1 !important;
}

.clickable-container {
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}

.justify-self-right {
  justify-self: right;
}

.cursor-pointer:hover {
  cursor: pointer;
  color: $secondary-blue;
}

.grid-two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;

  @include mobile-sm {
    display: block;
  }
}

// BACKGROUND COLOR

.bg-inherit {
  background-color: inherit;
}

.bg-light-gray {
  background-color: $gray-200;
}

.bg-primary-var-light-blue {
  background-color: $primary-var-light-blue;
}

.bg-submit {
  background-color: $var-green !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-primary {
  background-color: $primary-dark-blue !important;
}

.bg-light-primary {
  background-color: $primary-var-blue;
}

.bg-secondary {
  background-color: $secondary-blue !important;
}

.bg-light-secondary {
  background-color: $secondary-var-blue !important;
}

.bg-success {
  background-color: $green !important;
}

.bg-light-success {
  background-color: $var-green !important;
}

.bg-warning {
  background-color: $warning-yellow !important;
}

.bg-light-warning {
  background-color: $var-warning-yellow !important;
}

.bg-danger {
  background-color: $red !important;
}

.bg-light-danger {
  background-color: $var-red !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

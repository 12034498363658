@mixin custom-btn($main-color, $font-color) {
  background-color: $main-color;
  color: $font-color !important;
  opacity: 1 !important;
  transition: 0.3s;

  &.outline {
    border: 1px solid $main-color !important;

    &:hover {
      background-color: rgba($main-color, $alpha: 0.05);
    }

    &.no-border {
      border: none !important;
    }

    &.no-shadow {
      box-shadow: none !important;
    }

    &.no-bg-active:active {
      background-color: transparent;
      color: lighten($main-color, 20%);
    }
  }
}

a.btn {
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: lighten($secondary-blue, 10%);
  }

  &-secondary,
  &-cancel,
  &-outline {
    color: $primary-dark-blue;
  }
}

a.btn,
.btn {
  --btn-text-size: 1.2rem;
  --btn-height: 4rem;
  $btn-color: $secondary-blue;

  min-height: var(--btn-height);
  padding: 1rem 2rem;
  border-radius: calc(var(--btn-height) / 2);
  background: $btn-color;
  color: white;
  font-weight: bold;
  font-size: var(--btn-text-size);
  letter-spacing: calc(var(--btn-text-size) * 0.12);
  line-height: calc(var(--btn-text-size) * 1.2);
  border: 1px solid transparent;
  transition: 0.3s;
  width: unset;

  &-block {
    width: 100%;
  }

  &.no-shadow {
    box-shadow: none !important;
  }

  &:hover {
    border-color: transparent;
    background-color: lighten($secondary-blue, 10%);
  }

  &.non-clickable {
    pointer-events: none !important;
  }

  // Variations

  // Size
  &-xs {
    --btn-height: 2.5rem;
    padding: 0.5rem 1rem;
    font-size: calc(var(--btn-text-size) * 0.8);
    text-transform: uppercase;
  }

  &-sm {
    --btn-height: 3.5rem;
    padding: 0.5rem 2rem;
    font-size: calc(var(--btn-text-size) * 0.9);
  }

  &-big {
    --btn-height: 5rem;
    font-size: calc(var(--btn-text-size) * 1.2);
  }

  &.mobile-w-100 {
    @include mobile {
      width: 100%;
    }
  }

  // Colors
  &-primary-dark {
    $btn-main-color: $primary-dark-blue;

    @include custom-btn($btn-main-color, $white);

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($btn-main-color, 20%);
    }
  }

  &-aqua {
    $btn-main-color: #64bfb0;
    @include custom-btn($btn-main-color, $black);

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($btn-main-color, 20%);
    }
  }

  &-secondary-blue {
    $btn-main-color: $secondary-var-blue;

    @include custom-btn($btn-main-color, $black);

    border: 1px solid $secondary-blue;

    &:hover {
      background-color: lighten($btn-main-color, 5%) !important;
    }

    &.outline {
      border-top: 1px solid $secondary-blue;
    }

    &:hover {
      border: 1px solid $secondary-blue;
      background-color: lighten($btn-main-color, 15%);
    }
  }

  &-primary-light {
    $btn-main-color: $primary-var-blue;

    @include custom-btn($btn-main-color, $primary-dark-blue);

    &:hover {
      background-color: darken($btn-main-color, 10%);
    }
  }

  &-secondary {
    $btn-main-color: $secondary-light-blue;

    @include custom-btn($btn-main-color, $primary-dark-blue);

    &:hover,
    &:focus,
    &:active {
      background-color: darken($btn-main-color, 10%) !important;
    }
  }

  &-secondary-light {
    $btn-main-color: $secondary-light-blue-30-light;

    @include custom-btn($btn-main-color, $primary-dark-blue);

    &:hover,
    &:hover:active,
    &:hover:focus {
      background-color: var(--primary-var-blue) !important;
      color: $primary-dark-blue;
      border: 1px solid $secondary-light-blue;
    }

    &.outline:hover {
      background-color: var(--primary-var-light-blue) !important;
      border-color: $secondary-light-blue;
    }

    &:focus {
      background-color: $btn-main-color;
      color: $primary-dark-blue;
      border-color: $secondary-light-blue;
    }
  }

  &-secondary-var-blue {
    $btn-main-color: $secondary-var-blue;

    @include custom-btn($btn-main-color, $black);

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($btn-main-color, 10%) !important;
    }
  }

  &-success {
    $btn-main-color: $var-success;

    @include custom-btn($btn-main-color, $black);

    &:hover {
      background-color: lighten($btn-main-color, 10%);
    }
  }

  &-warning {
    $btn-main-color: $var-warning-yellow;

    @include custom-btn($btn-main-color, $black);

    &:hover {
      background-color: lighten($btn-main-color, 5%);
    }
  }

  &-danger {
    $btn-main-color: $var-red;

    @include custom-btn($btn-main-color, $red);

    &:hover {
      background-color: lighten($btn-main-color, 5%);
    }
  }

  &-danger-validated {
    $btn-main-color: $var-red;

    @include custom-btn($btn-main-color, $red);

    &:hover {
      background-color: lighten($btn-main-color, 5%);
    }
  }

  &-strong-danger {
    $btn-main-color: $red;

    @include custom-btn($btn-main-color, white);

    &:focus,
    &:hover {
      background-color: lighten($btn-main-color, 5%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $var-red !important;
      border-color: $var-red;
    }

    &:active,
    &.active {
      background-color: lighten($btn-main-color, 5%) !important;
      border-color: $var-red !important;
    }
  }

  &-primary-light-blue {
    background-color: $primary-light-blue !important;
    color: $primary-dark-blue !important;

    &:hover {
      background-color: $primary-var-blue !important;
    }
  }

  &-primary-validated {
    background-color: $primary-var-blue !important;
    color: $primary !important;

    &:hover {
      background-color: $primary-var-blue !important;
    }
  }

  &-link {
    --btn-height: 2rem;
    $btn-main-color: transparent;
    border: none !important;

    @include custom-btn($btn-main-color, $secondary-blue);

    &:hover,
    &:focus,
    &:focus-visible,
    &:active,
    &.active {
      background-color: $btn-main-color !important;
      box-shadow: none !important;
      border: none !important;
      outline: none !important;
      text-decoration: none;
    }

    &:hover {
      color: lighten($secondary-blue, 25%) !important;
    }
  }

  &-disabled,
  &.disabled,
  &:disabled {
    $btn-main-color: $gray-200;
    pointer-events: none;

    background-color: $btn-main-color;
    color: $grey;
    border-color: transparent;
    box-shadow: none;
  }

  &-cancel {
    $btn-main-color: $white;

    background-color: $btn-main-color;
    border-color: $gray-400;
    color: $black;

    &:hover,
    &:active,
    &:focus {
      color: $black !important;
      background-color: rgba($grey, $alpha: 0.1) !important;
      border-color: $grey !important;
    }
  }

  &-transparent {
    $btn-main-color: transparent;
    box-shadow: none !important;

    --icon-color: #1240d4;

    &.big-icon {
      transform: scale(1.2);
    }

    @include custom-btn($btn-main-color, var(--icon-color));

    &:hover {
      background-color: $btn-main-color;
      color: var(--icon-color);
    }

    &:active,
    &:focus {
      background-color: $btn-main-color !important;
      border-color: $btn-main-color !important;
      color: var(--icon-color);
    }

    // Transparent Variations
    &.danger {
      --icon-color: #ab0000;
    }

    &.warning {
      --icon-color: #f5b900;
    }

    &.green {
      --icon-color: #0da741;
    }

    &.no-primary {
      --icon-color: #919191;
    }

    &.primary-light-blue {
      --icon-color: #21b0f2;

      &:hover {
        background-color: $primary-var-light-grey;
      }
    }

    &.black {
      --icon-color: var(--black);
    }

    &.white {
      --icon-color: white;

      &:hover {
        color: var(--secondary-light-blue) !important;
      }
    }

    &.outline-white {
      --icon-color: white;
      border-color: white;

      &:hover {
        background-color: rgba($color: white, $alpha: 0.2);
      }

      &:active,
      &:focus {
        border-color: white !important;
      }
    }

    &.outline-white {
      --icon-color: white;
      border-color: white;

      &:hover {
        background-color: rgba($color: white, $alpha: 0.2);
      }

      &:active,
      &:focus {
        border-color: white !important;
      }
    }

    &.outline-dark {
      --icon-color: $black;
      border-color: $black;

      &:hover {
        background-color: rgba($color: $black, $alpha: 0.2);
      }

      &:active,
      &:focus {
        border-color: $black !important;
      }
    }

    &.outline-white-bgblue {
      --icon-color: white;
      border-color: white;
      background-color: $primary-dark-blue;

      &:hover {
        background-color: rgba($color: $primary-dark-blue, $alpha: 0.6);
      }

      &:active,
      &:focus {
        border-color: white !important;
      }
    }

    &-disabled,
    &.disabled,
    &:disabled {
      --icon-color: #ced4da;
      background-color: transparent;
    }
  }

  &-datatable {
    $btn-main-color: transparent;
    box-shadow: none !important;
    --icon-color: var(--black);

    @include custom-btn($btn-main-color, var(--icon-color));

    &.dark {
      background-color: $black;
      --icon-color: white;
    }

    &.danger {
      background-color: var(--red);
      --icon-color: white;
    }

    &.warning {
      background-color: var(--warning-yellow);
    }
  }

  &-submit {
    $btn-main-color: $green-submit;

    @include custom-btn($btn-main-color, $black);

    &:hover {
      background-color: darken($btn-main-color, 10%);
    }
  }

  // Outline

  &.outline {
    background-color: white;
    border: 1px solid $btn-color;
    color: $primary-dark-blue;

    &:hover {
      background-color: $light-grey;
    }
  }

  &-success-outline {
    $btn-main-color: white;

    @include custom-btn($btn-main-color, $black);

    border: 2px solid $var-success !important;

    &:hover {
      background-color: lighten($btn-main-color, 10%);
    }
  }

  &-success-validated {
    $btn-main-color: $var-success;

    @include custom-btn($btn-main-color, $success);

    &:hover {
      background-color: lighten($btn-main-color, 10%);
    }
  }
}

.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  --icon-size: 1;

  &.btn-sm {
    --icon-size: 0.8;
  }

  &.btn-big {
    --icon-size: 1.2;
  }

  &,
  &.btn-sm,
  &.btn-big {
    &--icon {
      margin-left: 1.2rem;
      transform: scale(var(--icon-size));
    }
  }

  &.non-clickable {
    pointer-events: none;
    opacity: 0.7;
  }

  &.edit-btn {
    .icon-btn--icon {
      margin: 0 0 3px 3px !important;
      transform: scale(var(--icon-size)) rotate(-45deg);
    }
  }
}

.bullet-switch-btn {
  display: flex;
  align-items: center;

  &__title {
    @extend .bullet-element, .primary-dark-blue;

    margin-right: 1.5rem;
    width: 11rem;
  }

  .custom-switch-label {
    margin: 0;
  }
}
